
import { defineComponent, reactive } from "vue";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";

export default defineComponent({
  components: {},
  setup() {
    const role = api.getRole();
    const dataMP = reactive({
      data: [] as any,
      page: 1,
      pageSize: 12,
      search: "",
      jenis: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
      listJenis: [] as any,
    });

    const getData = async () => {
      // cash("#loadingMP").show();

      // const hide = () => cash("#loadingMP").hide();
      const page = dataMP.page;
      const pageSize = dataMP.pageSize;
      const search = dataMP.search;
      const jenis = dataMP.jenis;
      const url =
        (await api.getConfigFeatureMember("memberMateri", "featureMateri")) +
        "pdf/" +
        "?search=" +
        search +
        "&page=" +
        page +
        "&pageSize=" +
        pageSize +
        (role === "member" ? "&jenis=" + jenis : "");

      console.log("role url ", role, url);

      const hitApi = await api.hitApiGet(url);
      const ds = hitApi.data.diagnostic;
      const rs = hitApi.data.response;

      dataMP.data = rs;
      dataMP.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);
    };

    const getJenisMateri = async () => {
      // gpf.gLoading.show();

      // const hide = () => gpf.gLoading.hide();
      const url = api.memberMateriJenis;
      const hitApi = await api.hitApiGet(url);
      const rs = hitApi.data.response;
      dataMP.listJenis = rs;
      dataMP.jenis = rs[0].id;
    };

    const hitFirst = async () => {
      if (role === "member") {
        await getJenisMateri();
        await getData();
      } else {
        await getData();
      }
    };
    hitFirst();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataMP.page = p;
      getData();
    };

    const nextPage = () => {
      dataMP.page = dataMP.page + 1;
      getData();
    };

    const beforePage = () => {
      dataMP.page = dataMP.page - 1;
      getData();
    };

    const lastPage = () => {
      dataMP.page = dataMP.paginate.totalPages;
      getData();
    };

    return {
      getData,
      dataMP,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      role,
    };
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.dataMP;
      ex[f] = val;
    },
  },
});
